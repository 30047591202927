<template>
  <div class="help">
    <div class="header" height="66px">
      <Iconfont class="alarm-tip" :size="22" name="icon-fanhuishouye"></Iconfont>
      <label @click="goRoute">返回首页</label>
    </div>
    <div class="content">
      <img src="../../assets/images/Help/banner_1.png" />
    </div>
    <div class="device">
      <label>微信小程序，功能丰富，简单易用！</label>

      <el-carousel :autoplay="false">
        <el-carousel-item v-for="(item,index) in imageLost" :key="index">
          <div class="carousel__img">
            <img :src="item.src" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { Login } from '@/router/modules/Login';

export default {
  name: 'Help',

  data() {
    return {
      imageLost: [
        { src: require('@/assets/images/Help/carousel_1.png') },
        { src: require('@/assets/images/Help/carousel_2.png') },
        { src: require('@/assets/images/Help/carousel_3.png') },
        { src: require('@/assets/images/Help/carousel_4.png') },
        { src: require('@/assets/images/Help/carousel_5.png') }
      ]
    };
  },

  methods: {
    goRoute() {
      const nav = Login;

      this.$router.push(nav.path);
    }
  }
}
</script>

<style lang="scss" scoped>
.help {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.header {
  width: 75%;
  height: 66px;
  margin: 0 auto;
  text-align: right;
  line-height: 66px;
  label {
    font-size: 16px;
    color: #858585;
    margin-left: 10px;
    cursor: pointer;
  }
}
.content {
  width: 75%;
  height: auto;
  margin: 0 auto;
  img {
    width: 100%;
    display: block;
    position: relative;
  }
}
.banner_1 {
  width: 100%;
  height: auto;
}

.device {
  width: 75%;
  box-sizing: border-box;
  text-align: center;
  background: #f2f3f5;
  margin: 3% auto 1%;
  label {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    color: #0b0b0b;
    line-height: 100px;
  }
}
.el-carousel {
  width: 93%;
  height: 780px;
  margin: 0 auto;
  .carousel__img {
    width: 100%;
    img {
      width: auto;
    }
  }
}

::v-deep {
  .el-carousel__container {
    height: 96%;
  }
}

@media only screen and (max-width: 1366px) {
  .el-carousel {
    width: 100%;
    height: 680px;
  }

  .carousel__img {
    width: 100%;
    img {
      width: 100% !important;
    }
  }
}
</style>